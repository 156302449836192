import React from 'react';
import './About.scss';

const About = () => {
    return (
        <div className="about-page">
            <div className="about-container">
                <div className="about-content">
                    <div className="profile-image">
                        <img src="/images/profile.png" alt="Jiayun Qin" />
                    </div>
                    <div className="about-text">
                        <div className="about-title">About me</div>
                        <p>
                            My name is Jiayun Qin, a UX/UI designer based in Ottawa with over four years experience. I love using my creativity and problem skills to create digital experiences that is not only aesthetically pleasing but also seamless for users. I believe design is a powerful way to help people solve problems—through thoughtful consideration and empathy, we can make life better.
                        </p>
                        <p>
                            As a lifelong learner, I embrace being open-minded and continuously challenging myself with new journeys. In my spare time, I enjoy drawing, and recently started creating doodle art. I find it creatively beautiful to let the pen flow freely from my hand, allowing lines and shapes to form themselves.
                        </p>
                        <a href="/pdfs/Jiayun_Resume.pdf" target="_blank" className="jane-button resume-button">RESUME <span className="arrow">
                            <img src="/svgs/arrow.svg" alt="Arrow" />
                        </span></a>
                    </div>
                </div>
                <div className="skills-title">
                    <h2>I can help you with...</h2>
                </div>
                <div className="skills-grid">
                    <div className="skill-item">
                        <svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.1486 8.3113C24.3911 6.34495 23.9133 4.36462 23.2124 3.16011C22.4268 1.81012 19.9235 0.637891 17.7423 0.977803C15.9424 1.2583 14.2199 2.15273 12.8579 3.60877C12.0998 4.4192 11.4622 5.79639 11.7673 7.52411C11.8343 7.90297 12.2369 9.04112 12.4219 9.53672C11.9697 8.65955 9.80769 6.10534 6.81367 6.12574C3.60854 6.14759 1.25517 8.03564 0.682556 10.6752C-0.161077 14.5639 3.1095 18.5625 7.48614 19.6069C12.3863 20.7763 14.5496 20.829 19.5257 20.2354C23.3507 19.7791 29.495 17.9573 31.5369 14.8174C33.5789 11.6775 32.9683 8.10188 30.0414 6.66493C27.9775 5.65169 24.6855 6.84344 23.1486 8.3113Z" fill="#B1F1FF" stroke="#1F1F1F" stroke-linecap="round" />
                        </svg>

                        UX/UI design
                    </div>
                    <div className="skill-item">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6226 11.8141C12.5047 10.8419 12.6996 8.66063 13.3368 7.07156C14.51 4.14532 15.3327 3.01321 16.9212 1.87174C18.5097 0.730267 20.8126 0.141539 22.0119 1.61473C22.7604 2.59418 22.7007 4.91429 22.2574 5.98993C21.5616 7.67851 20.3412 9.54581 19.0476 10.756C18.4297 11.3342 17.8395 11.8415 17.2476 12.3772C18.8373 11.4049 20.424 11.1187 21.8604 10.9173C23.0779 10.7697 25.324 10.6728 26.8242 11.5274C27.6321 11.9876 28.7177 12.7167 28.6524 14.2556C28.6376 15.5182 26.7329 16.5402 26.0973 16.7267C24.2851 17.2584 21.7568 17.471 19.8417 17.3051C19.276 17.3161 18.2416 17.0955 17.298 16.8338C18.0073 17.3506 18.6928 18.0356 19.1101 18.6546C20.569 20.8191 21.0309 21.6699 21.6482 23.6601C22.5635 26.6111 21.8621 28.3216 20.6174 29.0084C19.0434 29.8767 16.2789 27.3909 15.5961 26.3752C14.2965 24.442 13.3745 20.7457 13.0772 18.4717C12.9056 17.1586 13.0539 18.334 12.9932 18.8708C12.7686 20.8564 11.9312 22.9067 11.088 24.6725C10.3122 26.2971 8.81635 28.6869 6.74616 28.5676C5.05796 28.4704 4.57998 24.8784 4.67914 23.8285C4.91727 21.3072 6.44788 18.5176 8.35006 16.8908C8.93008 16.3948 10.8393 15.117 10.8393 15.117C10.8393 15.117 9.68829 14.8418 9.15754 14.6901C6.66462 13.9776 4.56681 12.951 2.60913 11.1864C1.83516 10.4888 1.04539 8.65811 1.21583 7.37828C1.42306 5.8222 3.18433 5.60865 3.92906 5.51835C5.91501 5.27755 7.79681 6.07049 9.74553 7.78494C10.7212 8.64328 11.9942 10.7182 12.6226 11.8141Z" fill="#FFCCB6" stroke="#1F1F1F" stroke-linecap="round" />
                        </svg>

                        Responsive website design
                    </div>
                    <div className="skill-item">
                        <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.7532 27.8674C25.3716 27.1491 29.2759 26.7623 33.5289 24.9119C36.8546 23.2038 38.5126 21.2994 39.2264 19.3512C39.5443 18.4835 39.4123 16.1567 36.8546 15.7902C35.6235 15.6293 34.2143 15.8671 32.7137 16.49C29.9238 17.6481 28.3123 18.7932 26.871 20.1454C25.4682 21.4616 24.3712 22.5784 23.155 23.866C23.8687 22.8808 24.5238 21.8709 25.3716 20.6232C26.6392 18.7578 27.1185 18.3448 28.5212 15.7902C29.6474 13.7391 30.0888 12.8485 30.5839 10.0617C30.9567 7.96284 30.9139 6.11364 29.5164 4.64596C28.1188 3.17828 25.652 3.40002 24.2307 5.02986C22.7456 6.73294 21.9865 8.67638 21.4254 11.1455C20.5282 15.0935 20.771 16.7498 20.5282 20.1454C20.4044 21.876 20.3269 27.0797 20.3269 25.3452C20.3269 24.1861 20.3345 23.0267 20.3269 21.8676C20.2879 15.9862 20.3427 12.0014 18.0995 6.50069C16.772 3.24538 13.8293 1.11217 11.4613 1.00201C9.97616 0.932897 7.58558 2.6549 7.64645 5.02985C7.70731 7.4048 8.80157 8.82297 10.3692 11.1455C12.5687 14.404 14.3838 16.1359 16.2274 19.3512C17.7415 21.992 18.7457 23.6762 19.9242 26.1563C19.97 26.2526 20.0238 26.3828 20.0818 26.5296C19.1908 25.1986 17.6541 23.367 16.9983 22.7396C13.8629 19.7407 12.5145 18.8093 9.1316 16.9514C3.08284 13.6293 -0.439053 16.0753 2.28338 19.4286C4.26359 21.8676 7.31329 23.0677 12.5045 25.1786C16.7031 26.8859 19.7386 27.7597 20.6129 27.858C20.6976 28.0245 20.7532 28.0653 20.7532 27.8674Z" fill="#DEFFA9" />
                            <path d="M20.7532 27.8674C25.3716 27.1491 29.2759 26.7623 33.5289 24.9119C36.8546 23.2038 38.5126 21.2994 39.2264 19.3512C39.5443 18.4835 39.4123 16.1567 36.8546 15.7902C35.6235 15.6293 34.2143 15.8671 32.7137 16.49C29.9238 17.6481 28.3123 18.7932 26.871 20.1454C25.1033 21.8039 23.8213 23.1459 22.1742 24.9119C21.76 25.3561 21.3667 25.8917 21.1913 26.4674C21.0379 26.9713 21.8124 25.5956 22.1505 25.1786C23.3264 23.7286 24.1483 22.4233 25.3716 20.6232C26.6392 18.7578 27.1185 18.3448 28.5212 15.7902C29.6474 13.7391 30.0888 12.8485 30.5839 10.0617C30.9567 7.96284 30.9139 6.11364 29.5164 4.64596C28.1188 3.17828 25.652 3.40002 24.2307 5.02986C22.7456 6.73294 21.9865 8.67638 21.4254 11.1455C20.5282 15.0935 20.771 16.7498 20.5282 20.1454C20.4044 21.876 20.3269 27.0797 20.3269 25.3452C20.3269 24.1861 20.3345 23.0267 20.3269 21.8676C20.2879 15.9862 20.3427 12.0014 18.0995 6.50069C16.772 3.24538 13.8293 1.11217 11.4613 1.00201C9.97616 0.932897 7.58558 2.6549 7.64645 5.02985C7.70731 7.4048 8.80157 8.82297 10.3692 11.1455C12.5687 14.404 14.3838 16.1359 16.2274 19.3512C17.7415 21.992 18.7457 23.6762 19.9242 26.1563C20.1961 26.7285 20.7532 28.4939 20.7532 27.8674ZM20.7532 27.8674C20.7532 27.0922 17.9495 23.6494 16.9983 22.7396C13.8629 19.7407 12.5145 18.8093 9.1316 16.9514C3.08284 13.6293 -0.439053 16.0753 2.28338 19.4286C4.26359 21.8676 7.31329 23.0677 12.5045 25.1786C16.9699 26.9943 20.1197 27.8674 20.7532 27.8674Z" stroke="#1F1F1F" stroke-linecap="round" />
                        </svg>

                        User research
                    </div>
                    <div className="skill-item">
                        <svg width="22" height="34" viewBox="0 0 22 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.01214 13.5041C8.28309 11.863 10.8939 7.62798 12.1188 2.47743C12.2021 5.32198 12.8388 10.1365 15.4177 12.4269C16.6767 13.5452 19.5898 15.4397 21.2958 15.7682C21.6296 15.8325 19.4385 15.901 18.1514 17.0738C14.5484 20.3569 14.2949 23.3034 13.6959 28.349C13.4862 30.1156 13.4627 31.8977 13.6135 33.4665C13.596 33.5225 13.3313 31.4749 13.3202 31.37C12.6753 25.2403 10.2815 18.1348 4.17791 14.7259C3.25799 14.2121 2.47326 14.3113 1.51724 14.1315C1.28322 14.0875 1.97675 14.0641 2.20284 14.0171C2.89194 13.8741 3.32864 13.7667 4.01214 13.5041Z" fill="#FFDA00" />
                            <path d="M12.1188 2.47743C10.8939 7.62798 8.28309 11.863 4.01214 13.5041C3.32864 13.7667 2.89194 13.8741 2.20284 14.0171C1.97675 14.0641 1.28322 14.0875 1.51724 14.1315C2.47326 14.3113 3.25799 14.2121 4.17791 14.7259C10.2815 18.1348 12.6753 25.2403 13.3202 31.37C13.3313 31.4749 13.596 33.5225 13.6135 33.4665C13.4627 31.8977 13.4862 30.1156 13.6959 28.349C14.2949 23.3034 14.5484 20.3569 18.1514 17.0738C19.4385 15.901 21.6296 15.8325 21.2958 15.7682C19.5898 15.4397 16.6767 13.5452 15.4177 12.4269C12.8388 10.1365 12.2021 5.32198 12.1188 2.47743ZM12.1188 2.47743C12.2375 1.41454 12.2138 1.62712 12.3215 0.662111" stroke="#1F1F1F" stroke-linecap="round" />
                        </svg>

                        Logo & Branding
                    </div>
                    <div className="skill-item">
                        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4182 1.24713C16.3823 1.18263 19.5437 2.29125 22.5171 3.58752C20.5358 3.24445 11.7169 6.11957 10.6958 12.0361C9.67798 17.9333 14.0386 18.8974 17.105 19.5754L17.1351 19.582C17.8938 19.7498 22.5171 20.082 25.0759 17.8437C24.6351 21.2116 16.3321 28.7811 8.13505 24.4543C-0.0619755 20.1274 0.337489 12.7886 1.69865 8.61224C3.40009 3.39181 8.87073 1.34608 13.4182 1.24713Z" fill="#A9FFFA" stroke="black" stroke-linecap="round" />
                        </svg>

                        Iconography
                    </div>
                    <div className="skill-item">
                        <svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.43718 10.5421C4.43718 10.5421 10.4809 4.59099 13.7536 0.99442C12.8491 3.18787 10.5586 7.70624 10.5586 7.70624C10.5586 7.70624 7.9981 11.8232 8.7627 11.8491C8.92784 12.0916 11.1647 12.7054 12.9668 13.2487C14.8225 13.8082 18.3543 14.5917 18.3543 14.5917C18.3543 14.5917 14.5014 18.415 11.7603 20.6537C8.07095 23.6668 1.08982 28.8503 1.59386 27.731C2.0979 26.6117 3.56856 25.1144 4.69198 23.4119C5.81541 21.7093 8.81807 16.9761 8.81807 16.9761C8.81807 16.9761 1.37328 15.2344 0.615265 15.0542C-0.142751 14.874 4.43718 10.5421 4.43718 10.5421Z" fill="#FFDA00" stroke="#1F1F1F" stroke-linecap="round" />
                        </svg>
                        Graphic design
                    </div>
                </div>
            </div>
            <div className="exp-and-work">
                <div className="section experience">
                    <h4>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.565 2.43185C20.9965 1.01421 23.0035 1.01421 23.435 2.43185L27.2781 15.0582C27.5207 15.8554 28.1446 16.4793 28.9418 16.7219L41.5681 20.565C42.9858 20.9965 42.9858 23.0035 41.5681 23.435L28.9418 27.2781C28.1446 27.5207 27.5207 28.1446 27.2781 28.9418L23.435 41.5681C23.0035 42.9858 20.9965 42.9858 20.565 41.5681L16.7219 28.9418C16.4793 28.1446 15.8554 27.5207 15.0582 27.2781L2.43185 23.435C1.01421 23.0035 1.01421 20.9965 2.43185 20.565L15.0582 16.7219C15.8554 16.4793 16.4793 15.8554 16.7219 15.0582L20.565 2.43185Z" fill="#A9FFFA" stroke="black" />
                        </svg>
                        Experience
                    </h4>
                    <div className="item">
                        <p className="title">Intermediate UX designer</p>
                        <div className="date-company">
                            <span className="date">2020 Jan - Present</span>
                            <span className="company">March Networks</span>
                        </div>
                    </div>
                    <div className="item">
                        <p className="title">UI designer & Marketing specialist</p>
                        <div className="date-company">
                            <span className="date">2019 Feb - Dec</span>
                            <span className="company">Financial Eastern Star Inc.</span>
                        </div>
                    </div>
                    <div className="item">
                        <p className="title">UX designer(Co-op)</p>
                        <div className="date-company">
                            <span className="date">2016 Sep - Dec</span>
                            <span className="company">Public Service Commission of Canada</span>
                        </div>
                    </div>
                </div>
                <div className="section education">
                    <h4>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.565 2.43185C20.9965 1.01421 23.0035 1.01421 23.435 2.43185L27.2781 15.0582C27.5207 15.8554 28.1446 16.4793 28.9418 16.7219L41.5681 20.565C42.9858 20.9965 42.9858 23.0035 41.5681 23.435L28.9418 27.2781C28.1446 27.5207 27.5207 28.1446 27.2781 28.9418L23.435 41.5681C23.0035 42.9858 20.9965 42.9858 20.565 41.5681L16.7219 28.9418C16.4793 28.1446 15.8554 27.5207 15.0582 27.2781L2.43185 23.435C1.01421 23.0035 1.01421 20.9965 2.43185 20.565L15.0582 16.7219C15.8554 16.4793 16.4793 15.8554 16.7219 15.0582L20.565 2.43185Z" fill="#A9FFFA" stroke="black" />
                        </svg>
                        Education
                    </h4>
                    <div className="item">
                        <p className="title">Interactive Multimedia and Design</p>
                        <div className="date-company">
                            <span className="date">2013 - 2018</span>
                            <span className="company">Carleton University</span>
                        </div>
                    </div>
                    <div className="item">
                        <p className="title">Interactive Media Development</p>
                        <div className="date-company">
                            <span className="date">2013 - 2018</span>
                            <span className="company">Algonquin College</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
