import React, { useEffect } from 'react';

import './WebClientApplication.scss';

const WebClientApplication = () => {
    useEffect(() => {
        document.body.style.backgroundColor = '#fff';
        document.querySelector('.header').style.backgroundColor = '#C9DBFF80';
        return () => {
            document.body.style.backgroundColor = '';
            document.querySelector('.header').style.backgroundColor = '';
        };
    }, []);
    return (
        <div className="web-client-application">
            <div className="project-content">
                <div className="project-header">
                    <div className="project-title-top">Web Client Application</div>
                    <div className="project-subtitle">Video Software Application Design</div>

                    <div className="project-image-container">
                        <img
                            src="/images/project2.png"
                            alt="Web Client Application Interface"
                            className="project-image"
                        />
                    </div>
                </div>

                <div className="project-details">
                    <div className="details-left">
                        <div className="detail-section">
                            <div className='detail-title'>My role</div>
                            <div className='detail-content'>Intermediate UX designer</div>
                        </div>

                        <div className="detail-section">
                            <div className='detail-title'>Duration</div>
                            <div className='detail-content'>Mar 2023 - Present</div>
                        </div>

                        <div className="detail-section">
                            <div className='detail-title'>Tools</div>
                            <div className='detail-content'>Adobe XD, Figma, Balsamiq, Confluence</div>
                        </div>

                        <div href="#" className="jane-button">Product Link<span class="arrow"><img src="/svgs/arrow.svg" alt="Arrow" /></span></div>
                    </div>

                    <div className="details-right">
                        <div className='detail-title' style={{ marginBottom: '30px' }}>Overview</div>
                        <div className='detail-content'>
                            <span className='detail-content-bold'>Command Web Client</span> is March Networks' web-based video surveillance application,
                            and it is one of the major projects I've continued to work at my current company. As
                            business needs evolve, we continually add new features, maintain the existing services
                            and improve the user experience based on ongoing customer feedback. Here, I'll focus
                            on walking through my design process using launched features as an example, rather
                            than diving into product specifics, as some information is confidential.
                        </div>
                    </div>
                </div>

                <div className="project-sections">
                    <div className="container">
                        <div className="section" style={{ marginBottom: '80px' }}>
                            <div className="section-title">Problem Statement</div>
                            <p className="section-content">
                                March Networks' customers planning to switch to iOS are facing difficulties with the
                                company's Command Client software for video surveillance service, which is currently a
                                Windows-only application. This limitation restricts the customers base to Windows users,
                                making it difficulty for company to expand market share and reach new audiences. To
                                address this, the company plans to develop a new web-based application compatible
                                with iOS. This solution will help retain current customers, attract new iOS users, and
                                enable users to access the application on mobile devices from anywhere.
                            </p>
                        </div>

                        <div className="section">
                            <div className="section-title">Goals</div>
                            <ul className="goals-list">
                                <li>Enable access to the video system from anywhere, with no application installation required</li>
                                <li>Prioritize video security monitoring as the core function</li>
                                <li>Ensure seamless viewing of videos and camera feeds</li>
                                <li>Provide instant event notifications</li>
                                <li>Enable easy management of the topology and devices</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="project-analysis">
                    <div className="project-sections">
                        <div className="container">
                            <div className="project-title research-title">Research</div>
                            <img alt="research" style={{ height: '200px' }} className="research-icon" src="/svgs/research.svg"></img>
                            <div className="section">
                                <div className="section-title">Competitor Analysis</div>
                                <p className="section-content" style={{ gap: '20px' }}>
                                    Before starting to design, I explored the market to understand what’s available and to see how competitors are positioning their products. This gave me a clearer view of current trends in web video apps and provided inspiration and opportunities to guide my design decisions.
                                    <p style={{ marginBottom: '0px' }}>
                                        My team and I compiled a list of our three main competitors: Solink, Verkada, and DTiQ. I researched each company using publicly available resources, including their websites, user guides, and YouTube training videos. My focus was on analyzing their solutions to client business problems, the workflows in their video products, the features they emphasize, and any gaps or weaknesses in their offerings.
                                    </p>
                                </p>
                            </div>
                            <img alt="competitor analysis" className="competitor-analysis-image" src="/images/CompetitorAnalysis.png"></img>
                            <div className="section">
                                <div className="section-title">Command Client Review</div>
                                <p className="section-content">
                                    I’ve thoroughly reviewed the current desktop application, Command Client, since our goal was to create a web version of this product. Along with this transition, we planed to tackle existing pain points, addressing them in the new design. We also made sure the design was optimized to match the usability patterns of a web application.
                                </p>
                            </div>
                            <img alt="command client review" className="competitor-analysis-image" src="/images/CommandClientReview.png"></img>
                            <img alt="user group" className="research-icon user-group-icon" src="/svgs/user-group.svg"></img>

                            <div className="section">
                                <div className="section-title">User Groups</div>
                                <div className="section-content">
                                    <p>We have two primary user groups: Administrators and Regular Users. Each group has distinct objectives and daily tasks, leading to different workflows and expectations for using the application. Our design will focus on addressing the specific needs of these two key user groups.</p>
                                    <p className='user-persona-title'>
                                        User persona 1
                                    </p>
                                    <img alt="user persona 1" className="user-persona-image" src="/images/UserPersona1.png"></img>
                                    <p className='user-persona-title'>
                                        User persona 2
                                    </p>
                                    <img alt="user persona 2" className="user-persona-image" src="/images/UserPersona2.png" style={{ marginBottom: '0px' }}></img>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="project-analysis">
                    <div className="project-sections" style={{ marginBottom: '0px' }}>
                        <div className="container" style={{ paddingTop: '0px' }}>
                            <div className="project-title">Design</div>
                            <div className="section">
                                <div className="section-title">Design System</div>
                                <div className="section-content">
                                    <p>I started the design process with pen and paper, allowing me to quickly sketch out initial ideas. Once I had a foundation, I converted these sketches into wireframes using Balsamiq in Confluence.</p>
                                    <p className='user-persona-title'>Main framework wireframe</p>
                                    <img alt="main framework wireframe" className="user-persona-image" src="/images/Mainframeworkwireframe.png"></img>
                                    <img alt="decision" className="research-icon" src="/svgs/decision.svg" style={{ paddingTop: '50px' }}></img>

                                    <p>One pain point of the Command Client is that each topology tree has limited space within the vertical panel. In the new design, I aimed to maximize the vertical space for each tree. I’ve identified two potential design solutions to address this issue, but I also needed to comprehensively consider other functions to select the best option. I conducted a task analysis and compared the pros and cons of each solution, ultimately making the final design decision in collaboration with managers.</p>
                                    <p className='user-persona-title'>Video topology solution 1</p>
                                    <div className='topology'>
                                        <img alt="topology 1" className="topology-image" src="/images/Topology1.png"></img>
                                        <p >There is only one vertical panel containing three tabs, with each topology tree located in its own tab. Users can switch between trees by clicking the tabs within the panel.</p>
                                    </div>
                                    <p className='user-persona-title'>Video topology solution 2</p>
                                    <div className='topology'>
                                        <img alt="topology 2" className="topology-image" src="/images/Topology2.png"></img>
                                        <p >There are three vertical panels, with each button controlling the opening and closing of one topology tree panel independently.</p>
                                    </div>
                                    <p className='user-persona-title'>Task Analysis - Solution 1</p>
                                    <div className='table'>
                                        <div className="table-header">
                                            <div className="header-cell">Task</div>
                                            <div className="header-cell">Steps</div>
                                            <div className="header-cell">#Clicks</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="cell">View a logical tree(assuming the panel is close)</div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Click on trigger panel button</li>
                                                    <li>Click on the logical tree tab</li>
                                                </ol>
                                            </div>
                                            <div className="cell">#1-2</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="cell">View a video using maximum space(assuming the panel is open)</div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Click on the close panel button</li>
                                                </ol>
                                            </div>
                                            <div className="cell">#1</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="cell border-cell-left">Add a camera to personal tree</div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Open topology tree panel</li>
                                                    <li>Choose a camera from system/logical tree</li>
                                                    <li>Click on "Add to favourite" button</li>
                                                    <li>Choose a destination</li>
                                                    <li>Confirm</li>
                                                </ol>
                                            </div>
                                            <div className="cell border-cell-right">#5</div>
                                        </div>
                                    </div>

                                    <p className='user-persona-title'>Task Analysis - Solution 2</p>
                                    <div className='table'>
                                        <div className="table-header">
                                            <div className="header-cell">Task</div>
                                            <div className="header-cell">Steps</div>
                                            <div className="header-cell">#Clicks</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="cell">View a logical tree(assuming the panel is close)</div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Click on open logical panel button</li>
                                                </ol>
                                            </div>
                                            <div className="cell">#1</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="cell">View a video using maximum space(assuming the panel is open)</div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Click on close logical panel button</li>
                                                    <li>Click on close system&personal panel button if other two panels are open</li>
                                                </ol>
                                            </div>
                                            <div className="cell">#1-3</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="cell border-cell-left">Add a camera to personal tree</div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Open personal panel</li>
                                                    <li>Expand the destination folder</li>
                                                    <li>Open system/logical panel</li>
                                                    <li>Choose a camera from system/logical tree</li>
                                                    <li>Drag and drop the selected camera to destination</li>
                                                </ol>
                                            </div>
                                            <div className="cell border-cell-right">#5</div>
                                        </div>
                                    </div>

                                    <p className='user-persona-title'>Comparison</p>
                                    <div className='table comparison'>
                                        <div className="table-header">
                                            <div className="header-cell">Layout</div>
                                            <div className="header-cell">Pros</div>
                                            <div className="header-cell">Cons</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="cell">Layout 1
                                                One vertical panel contains three tabs for topology trees</div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Uses a single vertical panel with three tabs for topology trees, reducing extra panels and preserving space for video viewing.</li>
                                                    <li>Separates view and edit modes, creating a clearer, role-specific functional environment for users.</li>
                                                </ol>
                                            </div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Only one panel can be viewed at a time, so all three topology trees cannot be displayed simultaneously.</li>
                                                    <li>Lacks drag-and-drop functionality between topology trees.</li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="table-row">
                                            <div className="cell">Layout 2
                                                Three vertical panels for each topology tree independently</div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Each topology tree is displayed in its own panel, allowing independent opening and closing.</li>
                                                    <li>All three trees can be viewed simultaneously.</li>
                                                    <li>Supports drag-and-drop between panels, making view and edit mode in the same theme.</li>

                                                </ol>
                                            </div>
                                            <div className="cell">
                                                <ol>
                                                    <li>Having all three panels open takes significant visual space, reducing the area available for video viewing. </li>
                                                    <li>Requires three clicks to close all panels.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>


                                    <p className='user-persona-title'>Peer Review</p>
                                    <p>I presented the solutions and analysis to a broader audience, including sales engineers, customer success managers, and product managers, to gather feedback. Hearing diverse opinions and insights from different perspectives helps the team make well-informed design decisions.</p>


                                    <p className='user-persona-title'>Decision</p>
                                    <p>Finally, for the video panel design, we chose the first solution because it requires fewer clicks for most tasks. While this option doesn’t support drag-and-drop for cameras between panels in view mode, it provides a clearer experience by separating view and edit modes based on user roles. This approach allows users to focus on video viewing without distractions.</p>

                                </div>
                            </div>

                            <div className="project-title" style={{ paddingTop: '100px' }}>Prototype</div>
                            <div className="section" style={{ paddingBottom: '50px' }}>
                                <div className="section-title">Design System</div>
                                <div className="section-content">
                                    <p>For this project, I developed a comprehensive design system in Figma that includes March Networks’ icon library, color palette, typography, and UI components library. This system is shared across the design and development teams to ensure a cohesive style and maintain consistency throughout the design and development process. I won’t include examples here, as they are internal and confidential documents.</p>
                                    <img alt="prototype" className="user-persona-image smaller-image" src="/images/Prototype.png"></img>
                                </div>
                            </div>
                            <div className="section">
                                <div className="section-title">WCGA & ADA Accessibility</div>
                                <div className="section-content">
                                    <p>Throughout the design process, I adhered to principles and checklists to ensure the design compliance with WCAG and ADA standards, as required by law and company policy.</p>
                                    <img style={{ marginBottom: '0px' }} alt="checklist for wcag and ada compliance" className="user-persona-image smaller-image" src="/images/checklist_for_wcag_and_ada_compliance_mobile_app_beefa34d77 1.jpeg"></img>
                                    <span style={{ color: '#454545', textAlign: 'center', marginBottom: '50px' }}>Reference: https://www.vlinkinfo.com/ </span>
                                    <p>This is an example of how I used the WCAG color checker to ensure the primary color I selected meets accessibility standards when I created the colour palette.</p>
                                    <img style={{ marginBottom: '0px' }} alt="accessibility result" className="user-persona-image" src="/images/AccessibilityResult.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="project-sections" style={{ marginTop: '0px' }}>
                    <div className="container">
                        <div className="section mockup-section">
                            <div className="section-title mockup-title">Interactive mockup</div>
                            <img alt="mockup" className="mockup-image" src="/images/mockup.png"></img>
                            <div href="#" className="jane-button">Youtube Video Introduction<span class="arrow"><img src="/svgs/arrow.svg" alt="Arrow" /></span></div>
                        </div>
                    </div>
                </div>

                <div className="project-analysis">
                    <div className="project-sections">
                        <div className="container">
                            <div className="section">
                                <div className="section-title">Document</div>
                                <div className="section-content">
                                    <p>Last but not least, I documented all my design details in Confluence, including annotated screenshots of mock-ups, to clearly explain the design to the development team. This documentation supports accurate implementation and encourages ongoing communication throughout the process.</p>
                                </div>
                            </div>
                            <img style={{ marginTop: '50px', paddingBottom: '50px' }} alt="document" className="research-icon" src="/svgs/document.svg"></img>

                        </div>
                    </div>
                </div>

                <div className="project-sections project-disclaimer">
                    <div className="container">
                        <p><b>Disclaimer:</b> The visual designs shown on this page are recreated simulations, intended to demonstrate the design process. Due to confidentiality agreements and client restrictions, actual design content is not displayed. Product screenshots are sourced from publicly available marketing materials.</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default WebClientApplication;
