import React from 'react';
import RecentWorks from '../RecentWorks/RecentWorks';

import '../../App.scss';

function App() {
  return (
    <RecentWorks showAll={true} />
  );
}

export default App;