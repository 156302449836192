import './Introduction.scss';

function Introduction() {
  return (
    <section className="introduction-wrapper">
      <div className="introduction">
        <div className="content">
          <h3 className="hey-there">Hey there ! I'm</h3>
          <h1 className="name">JIAYUN</h1>
          <p className="description">
            a <span className="highlight">UX/UI designer</span> based in Ottawa. I am a problem solver, an empathetic designer and a collaborative communicator.
          </p>
        </div>
        <div className="decorations">
          <img src="/svgs/star1.svg" alt="Decorative star" className="star star-1" />
          <img src="/svgs/star2.svg" alt="Decorative star" className="star star-2" />
          <img src="/svgs/circle.svg" alt="Decorative circle" className="circle" />
          <img src="/svgs/saas.svg" alt="SaaS experience" className="saas" />
          <img src="/svgs/noncode.svg" alt="Non coding design" className="non-code" />
        </div>
      </div>
    </section>
  );
}

export default Introduction;