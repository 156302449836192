import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.scss';

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="header">
      <div className="logo" onClick={() => navigate('/')}>
        <img src="/svgs/logo.svg" alt="Logo" width="72" height="50" />
      </div>
      <div className="nav-links">
        <span onClick={() => navigate('/')} className={window.location.hash === '#/' || window.location.hash === '' ? 'current' : ''}>Home</span>
        <span onClick={() => navigate('/works')} className={window.location.href.includes('works') ? 'current' : ''}>Works</span>
        <span onClick={() => navigate('/about')} className={window.location.href.includes('about') ? 'current' : ''}>About</span>
      </div>
      {/* <div className="profile-icon">Me</div> */}
    </header>
  );
};

export default Header;
