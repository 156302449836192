import React from 'react';
import Introduction from '../Introduction/Introduction';
import RollingBanner from '../RollingBanner/RollingBanner';
import RecentWorks from '../RecentWorks/RecentWorks';

import '../../App.scss';

function App() {
    return (
        <>
            <Introduction />
            <RollingBanner />
            <RecentWorks />
        </>
    );
}

export default App;