import React from 'react';
import { useNavigate } from 'react-router-dom';
import './RecentWorks.scss';
const projects = [
  { id: 1, backgroundColor: '#C9DBFF', title: 'Command Web Client', description: 'VSaaS for March Networks', image: '/images/work1.png', link: '/works/WebClientApplication' },
  { id: 2, backgroundColor: '#C9F5FF', title: 'Priority Renewal Project ', description: 'Website design for Public Service Commission of Canada', image: '/images/Priority Renewal Project.png', link: '/works/PriorityRenewalProject' },
];

function RecentWorks({ showAll }) {
  const navigate = useNavigate();

  const handleMoreProjectsClick = (link) => {
    navigate(link);
  };

  return (
    <div className="recent-works">
      <h2 className="recent-works-title" style={{ marginTop: showAll ? '80px' : '150px' }}>{showAll ? 'My Works' : 'Recent Works'}</h2>
      <div className="projects-container">
        {
          projects.slice(0, showAll ? projects.length : 2).map((project) => (
            <div className="project-box" key={project.id} onClick={() => handleMoreProjectsClick(project.link)}>
              <img src={project.image} alt={project.title} style={{ backgroundColor: project.backgroundColor }} />
              <div className="project-info">
                <div className="project-title">{project.title}</div>
                <div className="project-description">{project.description}</div>
              </div>
            </div>
          ))
        }
        {!showAll && (
          <button className="jane-button" onClick={() => handleMoreProjectsClick('/works')}>
            More Projects
            <span className="arrow">
              <img src="/svgs/arrow.svg" alt="Arrow" />
            </span>
          </button>
        )}
      </div>

      <img className="sparkle top-left" src="/svgs/sparkle.svg" alt="Sparkle" />
      <img className="sparkle2 top-right" src="/svgs/sparkle2.svg" alt="Sparkle2" />
      <img className="cloud bottom-right" src="/svgs/cloud.svg" alt="Cloud" />
    </div>
  );
}

export default RecentWorks;
