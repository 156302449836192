import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import MyWorksPage from './components/MyWorksPage/MyWorksPage';
import Contact from './components/Contact/Contact';
import PriorityRenewalProject from './components/PriorityRenewalProject/PriorityRenewalProject';
import WebClientApplication from './components/WebClientApplication/WebClientApplication';
import ScrollToTop from './components/ScrollToTop';

import Header from './components/Header/Header';
import './App.scss';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/works" element={<MyWorksPage />} />
          <Route path="/works/PriorityRenewalProject" element={<PriorityRenewalProject />} />
          <Route path="/works/WebClientApplication" element={<WebClientApplication />} />
        </Routes>
      </div>
      <Contact />
    </Router>
  );
}

export default App;
