import React, { useState } from 'react';
import './ImageCarousel.scss';

function ImageCarousel({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel">
      <button className="carousel-button prev" onClick={goToPrevious}>
        &#8249;
      </button>
      <div className="carousel-content">
        <img 
          src={images[currentIndex]} 
          alt={`Result slide ${currentIndex + 1}`} 
          className="carousel-image"
        />
        <div className="carousel-dots">
          {images.map((_, index) => (
            <button
              key={index}
              className={`carousel-dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
      <button className="carousel-button next" onClick={goToNext}>
        &#8250;
      </button>
    </div>
  );
}

export default ImageCarousel; 