import React from 'react';
import './RollingBanner.scss';
const RollingBanner = ({ text = 'Welcome • 2024 • Jiayun • Portfolio  • ' }) => {
    text = text.repeat(2);
    return (
        <div id="animated-text-strip">
            <span class="marquee">{text}</span>
            <span class="marquee">{text}</span>
            <span class="marquee">{text}</span>
        </div>
    );
};

export default RollingBanner;